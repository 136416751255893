<template>
	<div class="datas" v-loading="loadings">
		<div class="navCommon">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{path:'datamanagement',query:{type:this.$route.query.type}}">数据管理
				</el-breadcrumb-item>
				<el-breadcrumb-item>添加介绍</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="text">
			<el-button type="primary">文字介绍</el-button>
			<el-input type="textarea" :autosize="{ minRows: 12, maxRows: 40}" placeholder="请输入内容" v-model="textarea2">
			</el-input>
		</div>
		<div class="img">
			<el-button type="primary">图片介绍</el-button>
			<el-upload accept="image/jpeg,image/png" action="#" :auto-upload="false" :file-list="ftyLogoList"
				list-type="picture-card" :before-upload="imgbeforeUpload" :on-change="imgChange" :on-remove="imgRemove"
				:on-preview="imgPreview">
				<i class="el-icon-plus"></i>
			</el-upload>
			<el-dialog :visible.sync="imgdialogVisible">
				<img width="100%" :src="imgdialogImageUrl" alt="">
			</el-dialog>
		</div>
		<div class="number">
			<el-button type="primary">价格设定</el-button>
			<el-input @input="inputchange($event)" type="number" v-model="renPrice" :min="0" placeholder="请输入价格">
				<template slot="append">元</template></el-input>
		</div>
		<div class="button">
			<el-button type="primary" @click="addTrimStyle">提交</el-button>
			<el-button @click="$router.push({path:'datamanagement',query:{type:$route.query.type}})">取消</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				loadings: false,
				imgdialogVisible: false,
				imgdialogImageUrl: '',
				imgList: [],
				renPrice: "",
				textarea2: '',
				ImageUrl: [],
				dialogImageUrl: "",
				dialogVisible: false,
				actionurl: "",
				Imgurl: [],
				ftyLogo: [],
				ftyLogoList: [],
				newsftyLogoList: [],
				ftyLogomodify: [],
				copyftyLogoList: [],
			}
		},
		created() {
			this.actionurl = this.$commonFun.serverSrc + "programme/uploadFile"
			if (this.$route.query.id) {
				this.queryTrimStyle(this.$commonFun.getBeforStr(this.$route.query.type, '-'), this.$commonFun.getAfterStr(
					this.$route.query.type, '-'))
			}
		},
		mounted() {

		},
		methods: {
			//图片上传
			imgChange(file) {
				var _this = this;
				var event = event || window.event;
				var file = event.target.files[0];
				this.loadings = true
				this.bcebosupload(file, 2)
			},
			imgRemove(file, fileList) {
				let gh = []
				// console.log(file)
				// console.log(fileList)
				if (fileList && fileList.length > 0) {
					fileList.forEach((e, m) => {
						// console.log(ftyLogoList)
						if (e.name) {
							this.ftyLogo.forEach((q, t) => {
								if (q.name == e.name) {
									gh.push(q.url)
								}
							})
						} else {
							gh.push(e.url)
						}
					})
				} else {
					this.ftyLogo = []
					this.ftyLogoList = []
				}
				this.$nextTick(() => {
					// console.log(gh)
					this.ftyLogo = gh
					this.copyftyLogoList = gh
				})
			},
			imgPreview(file) {
				this.imgdialogImageUrl = file.url;
				this.imgdialogVisible = true;
			},
			// 上传前判断类型大小
			imgbeforeUpload(file) {
				// console.log(file.type)
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
				// console.log(isJPG)
				// console.log(isLt2M)
				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 3MB!');
				}
				return isJPG && isLt2M;
			},
			//百度云上传
			bcebosupload(file, type) {
				var _this = this;
				var date = new Date().getTime(); //时间戳传到百度云与后端作为文件名称
				_this.loadings = true
				const config = {
					endpoint: "https://demand.cdn.bcebos.com", //传入Bucket所在区域域名
					credentials: {
						ak: "b8f224aa0b3b41998a3e5be533b05ecb", //您的AccessKey
						sk: "275414e726414fbe90defd870976cc78" //您的SecretAccessKey
					}
				};
				// let bucket = 'zhangzhiedu/upload/mokaostu/video/';
				let bucket = '';
				var key = 'upload/cetificate/' + date + '.' + file.name.split('.')[1];
				let client = new baidubce.sdk.BosClient(config);
				client.putObjectFromBlob(bucket, key, file)
					.then(async response => {
						// 成功
						_this.loadings = false
						_this.$message({
							message: '上传成功',
							type: 'success'
						});
						_this.ftyLogo.push({
							name: file.name,
							url: 'https://demand.cdn.bcebos.com/' + key
						})
						// if (_this.$route.query.id) {
						// 	_this.newsftyLogoList.push({
						// 		name: file.name,
						// 		url: 'https://demand.cdn.bcebos.com/' + key
						// 	})
						// }
					})
					.catch(error => {
						console.error(error)
						_this.$message({
							message: '上传失败',
							type: 'error'
						});
					});
			},
			inputchange() {
				this.$forceUpdate();
			},
			//获取装修风格数据
			queryTrimStyle(q, w) {
				this.$post('queryTrimStyle', {
					typeId: q,
					styleId: w
				}).then((res) => {
					this.textarea2 = res.renContent
					this.renPrice = res.renPrice
					// this.Imgurl = res.renImage.split(',')
					res.renImage.split(',').forEach((e, m) => {
						this.ftyLogoList.push({
							url: e
						})
						this.copyftyLogoList.push({
							url: e
						})
					})
				})
			},
			//提交
			addTrimStyle() {
				let _this = this
				let a = []
				if (_this.ftyLogo && _this.ftyLogo.length > 0) {
					_this.ftyLogo.forEach((e, m) => {
						if(e.url){
							a.push(e.url)
						}else{
							a.push(e)
						}
					})
				}
				if (_this.copyftyLogoList && _this.copyftyLogoList.length > 0) {
					_this.copyftyLogoList.forEach((e, m) => {
						if(e.url){
							a.push(e.url)
						}else{
							a.push(e)
						}
					})
				}
				// console.log(a)
				if (a && a.length > 0) {
					_this.Imgurl = Array.from(new Set(a)).join(",")
				} else {
					_this.Imgurl = ''
				}

				// console.log(_this.Imgurl)
				if (!_this.textarea2) {
					_this.$message({
						type: 'warning',
						message: '请填写简介!'
					});
					return false
				}
				if (!_this.renPrice) {
					_this.$message({
						type: 'warning',
						message: '请填写方案价格!'
					});
					return false
				}
				if (!_this.Imgurl || _this.Imgurl.length <= 0) {
					_this.$message({
						type: 'warning',
						message: '请上传至少一张图片!'
					});
					return false
				}
				if (_this.renPrice && _this.renPrice < 0) {
					_this.$message({
						type: 'warning',
						message: '价格最低为零'
					});
					return false
				}
				this.$post('addTrimStyle',{
					id:this.$route.query.id ? this.$route.query.id:0,
					renType:this.$commonFun.getBeforStr(this.$route.query.type, '-'),
					renStyle:this.$commonFun.getAfterStr(this.$route.query.type, '-'),
					renImage:this.Imgurl.toString(),
					renContent:this.textarea2,
					renPrice:this.renPrice
				}).then(()=>{
					this.$message({
						type: 'success',
						message: this.$route.query.id?'修改成功':'添加成功'
					});
					this.$router.push({path:'datamanagement',query:{type:this.$route.query.type}})
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.text {
		margin-bottom: 80px;
	}

	.button {
		text-align: center;
		margin-top: 40px;
	}

	.number {
		margin-top: 40px;
	}

	.navCommon {
		padding: 20px 0;
		margin-bottom: 20px;
	}
</style>
<style>
	.datas .el-button--primary {
		margin-bottom: 30px;
	}
</style>
